@use "sass:math";

//Google font
@import url('https://fonts.googleapis.com/css2?family=Annie+Use+Your+Telescope&family=Noto+Sans:wght@400;700&display=swap');


//Colors:
$purple: #66075b;
$light-grey: #f3f3f3;
$dark-grey: #333;
$blue: rgb(9, 108, 136);
$white: #fff;
$yellow: #f5d907;

//Fonts:
$mainfont: 'Noto Sans', sans-serif;

//Import jeet
@import '../../node_modules/jeet/scss/index';

//Import mixins
@import 'mixins';

//Layout components
@import 'layout';

//COMPONENTS
@import 'frontpage';
@import 'components/adminmenu';
@import 'components/personcards';
@import 'components/wishlist';
@import 'components/addWish';
@import 'components/calendar';

body {
    margin-top: 0;
    padding-top: 0;
    font-size: 18px;
    font-family: $mainfont;
}

header {
    background: $purple;
    margin: 0;
    padding: 0 1rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
        margin: 0;
        font-size: 2.5rem;
        font-family: 'Annie Use Your Telescope', cursive;
        color: #fff;

        @include md-breakpoint {
            font-size: 5.5rem;
        }

        span {
            display: block;
            font-size: 1rem;
            transform: rotate(-1deg);
            margin-right: -.5rem;
            clear: both;

            @include md-breakpoint {
                display: inline-block;
                font-size: 1.5rem;
                transform: rotate(-4deg);
                margin-right: -1rem;
                clear: none;
            }
        }
    }

    a {
        color: $white;
        text-transform: uppercase;
        text-decoration: none;
        border: 2px solid transparent;
        transition: ease-in-out .5s;
        display: inline-block;
        padding: .5rem;
        margin: .1rem .3rem;

        &:hover {
            color: $yellow;
            border: 2px solid $yellow;
        }
    }
}

.container {
    max-width: 1200px;
    width: 94%;
    margin: 0 auto;
}

.button {
    display: inline-block;
    padding: .5rem;
    background: $blue;
    color: $white;
    font-size: .9rem;
    border-radius: 3px;
    text-decoration: none;
    transition: ease-in-out .5s;
    cursor: pointer;

    &:hover {
        background: #000;
    }
}

footer {
    background: $blue;
    color: $white;
    font-size: 0.8rem;
    padding: 2rem;

    a {
        color: $white;
        text-decoration: underline;
    }
}