.flex {
    display: flex;
}

.flex-center-v {
    align-items: center;
}
.flex-center-h {
    justify-content: center;
}
.flex-center {
    @extend .flex-center-h;
    @extend .flex-center-v;
}

.loader {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}