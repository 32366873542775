#addform {
    width: 100%;
    //max-width: 800px;
    margin-bottom: 2rem;

    div.formfield {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
        @include md-breakpoint {
            flex-direction: row;
            align-items: center;
            gap: 1rem;
        }
      
        
        padding-bottom: .5rem;
        small {
            display: block;
            clear: both;
            overflow: hidden;
            margin: .5rem 0;
        }
    }

    label {
        width: 100%;
        @include md-breakpoint {
            width: 20%;
        }
    }
    input, select, textarea { 
        min-width: 300px;
        font-size: 1rem;
        font-family: $mainfont;
        padding: .5rem;
    }
}