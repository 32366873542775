#people {
    display: flex;
    justify-content: space-around;
    @include md-breakpoint {
        justify-content: space-between;
    }
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;

    .person {
        background: #f3f3f3;
        padding: 1rem;
        position: relative;
        width: 94%;
        margin-bottom: 2rem;
        box-sizing: border-box;
        @include md-breakpoint {
            width: calc(50% - 2rem);
        }
        
        h2 {
            position: absolute;
            padding: 1rem;
            background: $purple;
            color: #fff;
            font-size: 1.5rem;
            @include md-breakpoint {
                font-size: 2.5rem;
            }
            top: -2.5rem;
            left: -.5rem;
            text-transform: uppercase;
            font-family: 'Annie Use Your Telescope', cursive;
            transform: rotate(-4deg);
        }

        figure {
            width: 100%;
            height: auto;
            max-width: 200px;
            max-height: 200px;
            @include md-breakpoint {
               max-width: 400px;
               max-height: 400px;
            }
            border-radius: 50%;
            display: block;
            overflow: hidden;
            font-size: 2rem;
            margin: 0 auto;

            img {
                width: 100%;
                height: auto;
            }
        }

        svg {
            width: 24px;
            height: 24px;
            margin-right: 1rem;
        }

        .workinfo {
            background: $purple;
            color: #fff;
            padding: 1rem;
            margin-bottom: 1rem;
            margin-top: -1rem;

            @include sm-breakpoint {
                float: right;
                max-width: 40%;
            }

            a {
                color: #fff;
            }
        }

        ul {
            padding: 0;
            margin: 0 0 1rem 0;
            li {
                display: flex;
                @extend .flex-center-v;
            }

            &#social {
                display: flex;
                gap: 5px;
            }
        }
    }

}