#wishlistcontainer {
    @extend .container;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;

    h1 {
        width: 100%;
        clear: both;
        margin-bottom: -2rem;
    }

    .lastupdate {
        display: flex;
        flex-direction: row;
        gap: .5rem;

        svg {
            width: 24px;
        }
    }

    &.wishlistindividual {
        article {
            img.profile {
                width: 75px;
                top: -8rem;
                right: 0;

                @include sm-breakpoint {
                    width: 150px;
                    top: -3rem;
                    right: -1rem;
                }
            }
        }
    }

    article {
        width: 100%;
        @include lg-breakpoint {
            width: 48%;
        }

        background: $light-grey;
        padding: 1rem;
        box-sizing: border-box;
        position: relative;

        img.profile {
            width: 75px;
            top: -1rem;
            right: 0;
            border-radius: 50%;

            @include sm-breakpoint {
                width: 150px;
                top: -3rem;
                right: -1rem;
            }

            position: absolute;
            transform: rotate(4deg);
            z-index: 9;
        }

        svg {
            width: 24px;
        }

        ul {
            padding: 0;
            position: relative;
            z-index: 3;
            li {
                display: flex;
                align-items: center;
                flex-flow: row wrap;
                gap: 1rem;
                padding: .5rem 0;
                
    
                .label {
                    background: $light-grey;
                    color: $dark-grey;
                    font-size: .9rem;
                    padding: .2rem;
                    float: left;
                }

                img {
                    max-width: 32px;
                    z-index: 9;
                }

                a {
                    align-self: flex-end;
                }

                .wishdesc {
                    font-size: .9rem;
                }
            }

            &.wishes {
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #fff;
                li {
                    border-top: 1px solid #fff;
                    border-bottom: 1px solid #ddd;
                }
            }
        }
    }
}