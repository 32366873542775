#admin {
    ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        gap: .5rem;
        list-style-type: none;

        li {
            a {
                background-color: $purple;
                color: $white;
                display: inline-block;
                padding: .5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-decoration: none;

                svg {
                    width: 24px;
                }

                &.active {
                    background-color: $white;
                    color: #000;
                    border-top: 2px solid $purple;
                    border-left: 2px solid $purple;
                    border-right: 2px solid $purple;

                    svg {display: none;}
                }
            }
        }
    }
}