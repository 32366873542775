#calendar {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-evenly;
    padding: 1rem;

    h2 {
        display: block;
        width: 100%;
        clear: both;
        overflow: hidden;
        text-align: center;
        margin-bottom: 1rem;
    }

    article {
        padding-right: 80px;
        flex-grow: 1;
    }
}