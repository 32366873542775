article.eventCard {
    border: 1px solid #ccc;
    background: #f3f3f3;
    padding: .5rem;
    margin-bottom: 1rem;
    position: relative;

    img {
        width: 60px;
        height: auto;
        border: 2px solid #ccc;
        border-radius: 50%;
        position: absolute;
        top: -.5rem;
        right: -.3rem;
    }

    h3 {
        margin: 0;
        span {
            font-weight: 400;
            font-size: 1rem;
            display: block;
        }
    }

    svg {
        max-width: 24px;
    }
    p {
        margin: 0;
    }
}