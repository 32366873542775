#frontpage {
    display: flex;
    flex-direction: column;
    padding: .5rem;
    @include md-breakpoint {
        flex-direction: row-reverse;
        padding: 1rem;
    }
    #people {
        justify-content: start;
    }

    #events {
        @include md-breakpoint {
            margin-bottom: 2rem;
            min-width: 400px;
        }
    }
}

@import 'components/eventCard';